<template>
  <div class="container">
    <b-modal
        id="verifyModal"
        centered
        size="md"
        @shown="openModal"
    >
      <template #modal-header>
        <div
            class="popUp-header d-flex justify-content-between align-items-center"
        >
          <h5 class="m-0">{{verificationType === 'email' ? 'Email' : 'Contact Number'}}  Verification</h5>
          <b-button variant="outline-secondary" size="sm" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>

      <div>
        <!-- Step 1 -->
        <div v-if="currentStep === 1">
          <div v-if="verificationType === 'email'">
            <label>Email <span class="required">*</span></label>
            <div class="d-flex align-items-center">
              <input type="text" class="custom-input"
                     id="email"
                     placeholder="Enter Client Email Address"
                     @input="$v.formFields.email_address.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.email_address.value.$error || formErrors}"
                     v-model="formFields.email_address.value"
                     disabled
                     value="" ref="email" >
            </div>
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.email_address.value.required && $v.formFields.email_address.value.$error">
              Field is required
            </div>
            <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.email_address.value.required && $v.formFields.email_address.value.$invalid">
              Enter a valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors}}</div>
          </div>
          <div v-else-if="verificationType === 'phoneNumber'">
            <label>Contact Number <span class="required">*</span></label>
            <input type="text" class="custom-input"
                   placeholder="Enter Contact Number"
                   @input="$v.formFields.phone_number.value.$touch(); removeError();"
                   :class="{'form-control is-invalid':$v.formFields.phone_number.value.$error || formErrors }"
                   v-model="formFields.phone_number.value"
                   value=""
                   disabled
                   maxlength="10" ref="phone" >
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.phone_number.value.required && $v.formFields.phone_number.value.$error">
              Field is required
            </div>
            <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.phone_number.value.required && $v.formFields.phone_number.value.$invalid">
              Enter a valid contact number
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors}}</div>
          </div>
          <div>
            <b-button
                class="mt-3 send-btn"
                variant="success"
                @click="sendOtp(verificationType)"
                :disabled="isProcessing"
            >
              {{ isProcessing ? "Sending..." : "Send OTP" }}
            </b-button>
          </div>
        </div>

        <!-- Step 2 -->
        <div v-if="currentStep === 2">
          <div>
            <label class="opt-label mt-0">Enter OTP</label>
          </div>
          <div class="opt-num-wrap">
            <div>
              <v-otp-input
                  @on-complete="onChangeOTP"
                  @on-change="handleOnChange"
                  ref="otpInput"
                  :input-classes="errorClass"
                  separator="- "
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
              />
            </div>
          </div>
          <div class="valid-error mt-2 ml-2" v-if="otpErrorFlag">
            {{ otpErrorMsg }}
          </div>
          <b-button
              class="verify-btn mt-3"
              variant="success"
              @click="verifyOtp"
              :disabled="isProcessing"
          >
            {{ isProcessing ? "Verifying..." : "Verify" }}
          </b-button>

          <!-- <div class="login-highlight mt-2 d-flex justify-content-end">
                Resend OTP
              </div> -->
          <login-otp  @sendOTP="resendOtp(verificationType)"
                      :verificationType="verificationType"
                      :phoneNumber="formFields.phone_number.value"
                      :email="formFields.email_address.value"
                      :expirySecond=120 />
        </div>

        <!-- Step 3 -->
        <div class="verification-success" v-if="currentStep === 3">
          <div class="text-center">
            <img src="https://enrollmentlogo.s3.us-east-2.amazonaws.com/verificaiton.svg" alt="">
            <h4>Verification Successful!</h4>
            <p> {{verificationType === 'email' ? 'Email' : 'Phone number'}} verified successfully</p>
            <button @click="closeModal">Back to Enrollment</button>
          </div>
        </div>
      </div>

      <!--      <div class="mt-3" v-if="currentStep > 1">
              <div class="d-flex justify-content-center">
                <b-button
                    variant="outline-primary"
                    :disabled="currentStep === 1"
                    @click="prevStep"
                >
                  Previous
                </b-button>
                <b-button v-if="currentStep === 3" variant="success" @click="closeModal">
                  Close
                </b-button>
                &lt;!&ndash;           <b-button
                               v-if="currentStep < totalSteps"
                               variant="primary"
                               :disabled="currentStep === 1"
                               @click="nextStep"
                           >
                             Next
                           </b-button>&ndash;&gt;
              </div>
            </div>-->
    </b-modal>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import {checkValidPhoneNumber} from "../utils/validators";
import OtpInput from "@bachdgvn/vue-otp-input";
import NewLoginOTP from "../components/NewLoginOTP";
// import {checkValidPhoneNumber} from "../utils/validators";

export default {
  name: 'EmailPhoneVerification',
  data() {
    return {
      currentStep: 1,
      totalSteps: 3,
      contact: "",
      otp: "",
      contactError: false,
      otpError: false,
      isProcessing: false,
      formErrors: '',
      formErrorFlag: false,
      tempId: '',
      errorClass : '',
      otpErrorFlag: false,
      errorMsg: '',
      otpErrorMsg: '',
      formFields: {
        email_address: {
          value: '',
          required: true,
          read_only: false,
        },
        phone_number: {
          value: null,
          required: false,
          read_only: false,
        },
      }
    };
  },
  components: {
    'v-otp-input': OtpInput,
    'login-otp': NewLoginOTP
  },
  props: {
    verificationType: {
      type: String,
      default: ''
    },
    verificationObj: {
      type: Object,
      default: () => {}
    }
  },
  validations() {
    if (this.verificationType === 'email') {
      return {
        formFields: {
          email_address: {
            value: {
              required,
              email
            }
          },
        }
      }
    } else if (this.verificationType === 'phoneNumber') {
      return {
        formFields: {
          phone_number: {
            value: {
              required,
              checkValidPhoneNumber
            }
          },
        }
      }
    }
  },
  methods: {
    openModal () {
      let app = this
      app.tempId = window.localStorage.getItem('tempId');
      app.formFields.email_address.value = app.verificationObj.email
      app.formFields.phone_number.value = app.verificationObj.phoneNumber
      app.currentStep = 1
      app.isProcessing = false
      app.removeError();
    },
    onChangeOTP(otp) {
      let app = this
      app.otp = otp
      app.verifyOtp();
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    sendOtp(paramsType) {
      let app = this;
      app.$v.$touch();
      if (app.$v.$invalid) {
        return
      } else {
        app.isProcessing = true
        let params = {};
        if (paramsType === 'email') {
          params['contact'] = app.formFields.email_address.value
          params['enrollment_id'] = app.tempId
          params['type'] = 'email'
        } else if (paramsType === 'phoneNumber') {
          params['contact'] = app.formFields.phone_number.value
          params['enrollment_id'] = app.tempId
          params['type'] = 'phone'
        }
        axios.post(process.env.VUE_APP_API_BASE_STORE + '/send-otp', params)
            .then(function (response) {
              app.$notify({
                title: response.data.message,
                icon: 'ti-alert',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
              })
              app.nextStep();
              app.isProcessing = false
            })
            .catch(function (error) {
              console.log('error', error.response)
              if (error.response.data.status == 'error') {
                let errorMsg = error.response.data
                app.formErrorFlag = true
                app.formErrors = errorMsg.message
              }
              app.isProcessing = false
            });
      }
    },
    handleOnChange (otp) {
      let app = this
      if (otp.length < 6) {
        app.otp = otp
      }
      if (app.otpErrorFlag) {
        app.otpErrorFlag = false
        app.errorClass = ''
        app.errorMsg = ''
      }
    },
    verifyOtp() {
      let app = this
      if ((!this.otp) || (this.otp.trim() === "")) {
        app.otpErrorFlag = true;
        app.errorClass = 'highlight_otp'
        app.otpErrorMsg = `Please enter  OTP sent to your provided ${app.verificationType === 'email' ? 'email' : 'phone number'}  to proceed.`;

      } else if ((app.otp.length < 6)) {
        app.otpErrorFlag = true;
        app.errorClass = 'highlight_otp'
        app.otpErrorMsg = "Please enter  6 digit OTP inorder to proceed";
      } else {
        app.errorClass = false
        app.isProcessing = true
        let params = {};
        params['enrollment_id'] = app.tempId
        if (this.verificationType === 'email') {
          params['contact'] = app.formFields.email_address.value
          params['otp'] = app.otp
          params['type'] = 'email'
        } else if (this.verificationType === 'phoneNumber') {
          params['contact'] = app.formFields.phone_number.value
          params['otp'] = app.otp
          params['type'] = 'phone'
        }
        axios.post(process.env.VUE_APP_API_BASE_STORE + '/verify-otp', params)
            .then((response) => {
              if (response.data.status == "success") {
                app.$emit('verificationStatus', params)
                app.nextStep();
              }
            })
            .catch((error) => {
              app.errorClass = 'highlight_otp'

              if (error.response.data.status == "error") {
                app.otpErrorFlag = true
                app.otpErrorMsg = error.response.data.message
                app.isProcessing = false
              }
            })
      }
    },
    closeModal() {
      let app = this
      app.$bvModal.hide('verifyModal')
      this.resetSteps();
    },
    removeError () {
      let app = this
      app.formErrorFlag = false
      app.otpErrorFlag= false
      app.formErrors = ''
      app.otpErrorMsg = ''
      app.errorClass = ''
    },
    resetSteps() {
      this.currentStep = 1;
      this.contact = "";
      this.otp = "";
      this.contactError = false;
      this.otpError = false;
      this.isProcessing = false;
    },
    resendOtp(paramsType) {
      let app = this;
      app.$v.$touch();
      if (app.$v.$invalid) {
        return
      } else {
        app.isProcessing = true
        let params = {};
        if (paramsType === 'email') {
          params['contact'] = app.formFields.email_address.value
          params['enrollment_id'] = app.tempId
          params['type'] = 'email'
        } else if (paramsType === 'phoneNumber') {
          params['contact'] = app.formFields.phone_number.value
          params['enrollment_id'] = app.tempId
          params['type'] = 'phone'
        }
        axios.post(process.env.VUE_APP_API_BASE_STORE + '/send-otp', params)
            .then(function (response) {
              app.$notify({
                title: response.data.message,
                icon: 'ti-alert',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
              })
              app.isProcessing = false
            })
            .catch(function (error) {
              console.log('error', error.response)
              if (error.response.data.status == 'error') {
                let errorMsg = error.response.data
                app.formErrorFlag = true
                app.formErrors = errorMsg.message
              }
              app.isProcessing = false
            });
      }
    },
  },
  watch: {
    currentStep(newVal) {
      if (newVal == 3) {
        setTimeout(() => {
          let app = this
          app.$bvModal.hide('verifyModal')
        }, 2000)
      }
    }
  }
};


</script>

<style>
#verifyModal #contact-input .form-control.is-valid {
  background-color: transparent !important;
}

#verifyModal .otp-box {
  border-radius: 4px;
  border: 1px solid #dddddd;
  max-width: 56px;
  max-height: 45px;
  padding: 1rem;
  margin-right: 0.8rem;
  outline: none;
}
#verifyModal .verify-btn {
  min-height: 45px;
  padding: 0.5rem 0.8rem;
  font-size: 14px;
}
#verifyModal .resend-timer-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 14px;
}
#verifyModal .resend-timer {
  color: #5e5e5e !important;
  margin-left: 2px;
  font-weight: 600;
}
#verifyModal .no-border {
  border: none;
  outline: none;
  width: 80%;
}
#verifyModal .update-btn {
  background-color: #0871bb;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 0.2rem 0.8rem;
}

#verifyModal .verify-email {
  background-color: #11809c !important;
}
#verifyModal .send-btn {
  margin-bottom: 1rem;
}

#verifyModal .send-btn,
.verify-btn {
  width: 100%;
}
#verifyModal .dim-light {
  color: #ccc;
}
#verifyModal .resend-code-enable {
  color: #11809c;
  font-weight: 700;
  cursor: pointer;
}
#verifyModal .custom-check-wrap{
  background-color:#11809c ;
  border-radius: 50px;
  max-width: fit-content;
  max-height: fit-content;
  padding: 0.2rem 0.6rem;
  margin-left: 0.1rem 0.5rem;
}
#verifyModal .custom-check-wrap svg{
  filter: brightness(0) invert(1);
  border-radius: 0.8rem;
  font-size: 10px;
}

#verifyModal .opt-num-wrap {
  display: flex;
  align-items: center;
}

#verifyModal .opt-num-wrap input {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin: 0 10px 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  outline: none;
}


#verifyModal .highlight_otp {
  border: 2px solid #e73737 !important;
}

#verifyModal .valid-error {
  color: #e73737;
  padding: 0 10px;
}
.verification-success img{
  max-width: 250px;
}
.verification-success button {
  background-color: transparent;
  color: #11809c;
  border: 0;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 1rem;
}
</style>